import React from "react";
import styles from "./Services.module.css";

const Services = () => {
  return (
    <div name="Nosotros" className={styles.home}>
      <div className={styles.titleContainer}>
        <p>
          Tareas en general<br />
          <br />
          <b>         
          Habilitación y bloqueo de recepción de planillas de registro:<br /><br />
            - En caso de actualizar una planilla de notas después del bloqueo, este acceso cuenta 
            con un módulo de control.<br /><br />
            - Edición de inscripción.<br /><br />
            - Habilitación / bloqueo.<br /><br />
          Generación de documentos de inscripción y reportes.<br /><br />
          Asignación de materias y cursos a profesores.<br /><br />
          </b>
        </p>
      </div>
    </div>
  );
};

export default Services;
