import React from "react";
import styles from "./Home.module.css";

const Home = () => {
  return (
    <div name="Inicio" className={styles.home}>
      <div className={styles.titleContainer}>
        <div className={styles.fondo}>
          <img src="Imagenes/2.jpeg" alt="Fondo" />
        </div>
        <div className={styles.shield}>
          <img src="Imagenes/logo.png" alt="logo" />
        </div>

        <div className={styles.buttonContainer}>
          <a
            href="http://64.176.6.53:3000/login"
            className={styles.contactLink}
          >
            <b>Iniciar sesión</b>
          </a>
        </div>
        <br />
        <br />
        <p>
          Descripción <br />
          <br />
          <b>         
            El sistema académico <i>MaverickAcad</i> es una plataforma integral y responsiva totalmente configurable diseñada 
            para optimizar la gestión educativa y mejorar la experiencia de toda la comunidad escolar.
             Con una página web intuitiva, ofrece servicios personalizados para direcciones académicas,
              secretarías, profesores y estudiantes, facilitando la inscripción online y la gestión de 
              documentación. Además, incluye herramientas avanzadas para el seguimiento del rendimiento 
              académico.
          </b>
        </p>
      </div>
    </div>
  );
};

export default Home;
